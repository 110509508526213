import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStarship, faLaughBeam, faUsers } from '@fortawesome/pro-light-svg-icons'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { device } from '../components/Styles'

const Container = styled.div`
  margin: 0rem auto;
  padding: 1rem;

  @media ${device.laptop} {
    width: var(--width);
    padding: 0;
  }

  h1 {
    text-align: center;
  }
`
const Grid = styled.div`
  align-items: center;
  width: 100%;

  @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
  }
`
const Plan = styled.div`
  background-color: var(--color-slate-100);
  padding: 2rem;
  border-radius: 1rem;
  height: 100%;
  margin-bottom: 1rem;

  @media ${device.laptop} {
    margin-bottom: 0;
  }
`

const PlanTitle = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 3rem;
  font-weight: 600;
`
const PlanEntitlements = styled.ol`
  text-align: left;
  list-style-position: outside;
  list-style-type: disc;
  padding: 0;
`
const Entitlement = styled.li`
  padding: 0.5rem;
  border-bottom: 1px solid var(--color-gray-200);
  margin: 0;
`
const Amount = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: bold;
`
const Price = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
`
const Icon = styled.span`
  padding-right: 1rem;
`
const BuyMePlease = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`

const IndexPage = (): React.ReactElement => (
  <Layout>
    <SEO title="Pricing" />
    <Container>
      <h1>Get started for free</h1>
      <BuyMePlease>Streamline your engineering operations, and make your engineers happy</BuyMePlease>

      <Grid>
        <Plan>
          <PlanTitle>
            <Icon>
              <FontAwesomeIcon icon={faLaughBeam} />
            </Icon>
            Basic
          </PlanTitle>
          <Price>
            <Amount>$0</Amount> user / month
          </Price>
          <PlanEntitlements>
            <Entitlement>Upto 3 team members</Entitlement>
            <Entitlement>1hr max task run time</Entitlement>
          </PlanEntitlements>
        </Plan>
        <Plan>
          <PlanTitle>
            <Icon>
              <FontAwesomeIcon icon={faUsers} />
            </Icon>
            Team
          </PlanTitle>
          <Price>
            <Amount>$19</Amount> user / month
          </Price>
          <PlanEntitlements>
            <Entitlement>Upto 25 team members</Entitlement>
            <Entitlement>All basic features</Entitlement>
            <Entitlement>Approval flows</Entitlement>
            <Entitlement>User permissions</Entitlement>
            <Entitlement>24hr max task run time</Entitlement>
          </PlanEntitlements>
        </Plan>
        <Plan>
          <PlanTitle>
            <Icon>
              <FontAwesomeIcon icon={faStarship} />
            </Icon>
            Enterprise
          </PlanTitle>
          <Price>
            <Amount>Custom</Amount> user / month
          </Price>
          <PlanEntitlements>
            <Entitlement>Everything in the team plan</Entitlement>
            <Entitlement>Unlimited team members</Entitlement>
            <Entitlement>SAML SSO</Entitlement>
          </PlanEntitlements>
        </Plan>
      </Grid>
    </Container>
  </Layout>
)

export default IndexPage
